import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/atom-one-dark.css';

import groovy from 'highlight.js/lib/languages/groovy';
import kotlin from 'highlight.js/lib/languages/kotlin';

hljs.registerLanguage('groovy', groovy);
hljs.registerLanguage('kotlin', kotlin);

hljs.highlightAll();
